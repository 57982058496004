import React from 'react';
import {
    useLoadScript} from "@react-google-maps/api";
import './styles.css';

import Map from './Map';

const api_key = process.env.REACT_APP_GOOGLE_MAP_API_KEY

export default function ReferralMap({
    api, 
    postCode, 
    searchResult, 
    setSelectedClinic, 
    searchAgain, 
    brandingInfo,
    setIsClinicConfirmLoading,
    isClinicConfirmLoading,
    setConfirmClinicErrorMessage,
    confirmClinicErrorMessage,
    confirmClinicHandler,
    selectClinic
    }) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: api_key
    });

    return isLoaded ? <Map  api={api} 
                            searchAgain={searchAgain} 
                            postCode={postCode} 
                            searchResult={searchResult} 
                            setSelectedClinic={setSelectedClinic}
                            brandingInfo={brandingInfo}
                            setIsClinicConfirmLoading={setIsClinicConfirmLoading}
                            isClinicConfirmLoading={isClinicConfirmLoading}
                            setConfirmClinicErrorMessage={setConfirmClinicErrorMessage}
                            confirmClinicErrorMessage={confirmClinicErrorMessage}
                            confirmClinicHandler={confirmClinicHandler}
                            selectClinic={selectClinic}
                            /> : null;
}