import React, {useState, useEffect} from 'react';
import {
    GoogleMap, 
    InfoWindow, 
    Marker, 
    DirectionsRenderer} from "@react-google-maps/api";

import {LoadingIndicator} from '../../lib/iprs-react-library/src/index';
import './styles.css';

import markerIcon from './img/new/map-marker.svg';
import homeIcon from './img/new/house-marker.svg';

import carIcon from './img/new/map-car.svg';
import walkIcon from './img/new/map-walk.svg';
import trainIcon from './img/new/map-train.svg';
import cycleIcon from './img/new/map-cycle.svg';
import mapStart from './img/new/map-start.svg';
import mapEnd from './img/new/map-end.svg';

function Map({
        postCode, 
        searchResult, 
        setSelectedClinic, 
        searchAgain, 
        brandingInfo,
        isClinicConfirmLoading,
        confirmClinicErrorMessage,
        selectClinic
    }) {
    const [map, setMap] = useState(null)

    const [homeMarker, setHomeMarker] = useState('')
    const [clinicMarkers, setClinicMarkers] = useState('');
    const [activeMarker, setActiveMarker] = useState(null);
    const [center, setCenter] = useState()

    const [loadStatus, setLoadStatus] = useState({
        homeMarker: false,
        clinicMarkers: false,
        center: false
    })

    const [loadReady, setLoadReady] = useState(false)



    // Sets state of homeMarker and clinicMarkers when searchResult has been retrieved.
    useEffect(()=>{
        if(searchResult){
            setHomeMarker(searchResult.Home);
            setClinicMarkers(searchResult.FacilityList);
            setLoadStatus({...loadStatus, homeMarker: true, clinicMarkers: true});
        }
        // eslint-disable-next-line
    },[searchResult])

    // Sets state of center
    useEffect(()=>{
        if(homeMarker){

            if (homeMarker && homeMarker.Latitude !== undefined && homeMarker.Longitude !== undefined) {
                const lat = Number(homeMarker.Latitude);
                const lng = Number(homeMarker.Longitude);
            
                if (!isNaN(lat) && !isNaN(lng)) {
                    setCenter({ lat, lng });
                }
            } else {
                console.error('homeMarker is undefined or missing Latitude/Longitude:', homeMarker);
            }

            // console.log(homeMarker);
            // setCenter({lat: Number(homeMarker.Latitude), lng: Number(homeMarker.Longitude)});
            setLoadStatus({...loadStatus, 'center': true})
        }
        // eslint-disable-next-line
    },[homeMarker])

    useEffect(()=>{
        if(
            loadStatus['homeMarker'] &&
            loadStatus['clinicMarkers'] &&
            loadStatus['center'])
        {
            setLoadReady(true)
        } else {
            setLoadReady(false)
        }
        // eslint-disable-next-line
    },[homeMarker, clinicMarkers, center])

    const handleActiveMarker = (marker) => {
      if (marker === activeMarker) {
        return;
      }
      setActiveMarker(marker);
    };
  
    const handleOnLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds();

        clinicMarkers.forEach(i => {
            bounds.extend({lat: i.Latitude, lng: i.Longitude})
        });
        map.fitBounds(bounds);
        setMap(map);
    };

    useEffect(()=>{
        const bounds = new window.google.maps.LatLngBounds();
        if(typeof clinicMarkers === 'object'){
            clinicMarkers.forEach(i => {
                bounds.extend({lat: i.Latitude, lng: i.Longitude})
            })
        }
        if(map !== null){
            map.fitBounds(bounds);
            setMap(map);
        }
    },[clinicMarkers, map])

    // Getting the direction between the user coordinate and destination clinic
    const [travelMode, setTravelMode] = useState('DRIVING');
    const [direction, setDirection] = useState(null);
    const [clinicLocation, setClinicLocation] = useState(null);
    const [clinicAddress, setClinicAddress] = useState(null);

    const getDirections = (coordinate, address) => {
        setClinicAddress(address);
        setSelectedClinic(address)
        setClinicLocation({lat: coordinate.Latitude, lng: coordinate.Longitude})

        const DirectionsService = new window.google.maps.DirectionsService();

        DirectionsService.route(
            {
                origin: new window.google.maps.LatLng(homeMarker.Latitude, homeMarker.Longitude),
                destination: new window.google.maps.LatLng(coordinate.Latitude, coordinate.Longitude),
                travelMode: travelMode,
                unitSystem: window.google.maps.UnitSystem.IMPERIAL
            },
            (result, status) => {
                if(status === window.google.maps.DirectionsStatus.OK){
                    // console.log(result, status)
                    setDirection(result);
                }
            }
        );
    }

    // Resets direction state when searchAgain button is clicked.
    useEffect(()=>{
        setDirection(null);
    },[searchAgain])

    const loadMap = () => {
        return (
            <GoogleMap
                onLoad={handleOnLoad}
                onClick={() => setActiveMarker(null)}
                mapContainerStyle={{ width: "100%", height: "600px" }}
                defaultCenter={center}
            >
                <Marker position={center} icon={homeIcon}></Marker>
                {clinicMarkers.map(({ 
                    FacilityName, 
                    Address1,
                    Address2,
                    Address3,
                    Postcode,
                    WebAddress,
                    Latitude, 
                    Longitude,
                    FacilityID }, index) => (
                    
                    <Marker
                        key={index}
                        position={{lat: Latitude, lng: Longitude}}
                        onClick={() => handleActiveMarker(index)}
                        icon={markerIcon}
                    >
                        {activeMarker === index ? (
                            <InfoWindow 
                                onCloseClick={() => setActiveMarker(null)}
                                options={{width: 100}}
                            >
                                <div className="infoWindow">
                                    <h3>{FacilityName}</h3>
                                    <p>{Address1}</p>
                                    <p>{Address2}</p>
                                    <p>{Address3}</p>
                                    <p>{Postcode}</p>

                                    {WebAddress !== '' ? <a href={WebAddress}>Visit website</a> : <></>}

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                        <button onClick={()=>getDirections({Latitude, Longitude}, {FacilityName, Address1, Address2, Address3, Postcode, FacilityID})} className="green-button info_window">Get Directions</button>
                                        <button onClick={(e)=>selectClinic(e, FacilityID)} className="green-button info_window" disabled={isClinicConfirmLoading}>
                                            {isClinicConfirmLoading !== true ? 
                                                "Confirm Your Clinic" :
                                                <LoadingIndicator 
                                                    size={20}
                                                    loading={isClinicConfirmLoading}
                                                    color={"#FFFFFF"}
                                                />
                                            }
                                        </button>
                                        {
                                            confirmClinicErrorMessage !== "" ? <p class="confirmClinicPopupErrorMessage">{confirmClinicErrorMessage}</p> : <></>
                                        }
                                    </div>
                                </div>
                            </InfoWindow>
                        ) : null}
                    </Marker>
                ))}
            </GoogleMap>
        )
    }

    const panelSelector = React.useRef(null);

    const directionPanel = (            
        <div 
            ref={panelSelector}
            id="panel" 
        ></div>
    )


    // State and Function for DirectionRenderer component's onLoad prop trigger react to re render the DOM.

    const [reRender, setReRender] = useState(false);

    const makeReRender = () => {
        reRender?setReRender(false):setReRender(true);           
    }

    // State for Travel Mode
    const [modeType, setModeType] = useState({
        "DRIVING": true,
        "TRANSIT": false,
        "WALKING": false,
        "BICYCLING": false
    })

    const changeMode = (option) => {
        switch(option){
            case 'DRIVING':
                setModeType({
                    "DRIVING": true,
                    "TRANSIT": false,
                    "WALKING": false,
                    "BICYCLING": false            
                });
                break;
            case 'TRANSIT':
                setModeType({
                    "DRIVING": false,
                    "TRANSIT": true,
                    "WALKING": false,
                    "BICYCLING": false            
                });
                break;
            case 'WALKING':
                setModeType({
                    "DRIVING": false,
                    "TRANSIT": false,
                    "WALKING": true,
                    "BICYCLING": false            
                }); 
                break;
            case 'BICYCLING':
                setModeType({
                    "DRIVING": false,
                    "TRANSIT": false,
                    "WALKING": false,
                    "BICYCLING": true            
                });
                break;    
            default :
                return null;
        }
    }

    useEffect(()=>{
        for(const property in modeType){
            if(modeType[property]){
                setTravelMode(property)
            }
        }
    },[modeType])

    useEffect(()=>{
        if(clinicLocation !== null){
            const DirectionsService = new window.google.maps.DirectionsService();

            DirectionsService.route(
                {
                    origin: new window.google.maps.LatLng(homeMarker.Latitude, homeMarker.Longitude),
                    destination: new window.google.maps.LatLng(clinicLocation),
                    travelMode: travelMode,
                    unitSystem: window.google.maps.UnitSystem.IMPERIAL
                },
                (result, status) => {
                    if(status === window.google.maps.DirectionsStatus.OK){
                        setDirection(result);
                    }
                }
            );
        }
        
    },[travelMode, center, clinicLocation, homeMarker])

    const homeAndDestination = () => {
        const style = {
            display: 'flex',
            flexDirection: 'row',
            fontWeight: '500',
        }

        const iconOriginStyle ={
            backgroundImage: `url(${mapStart})`,
            width: '100%',
            backgroundPosition: 'left center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '12px',
            padding: '0.1em 2em'
        }

        const iconDestinationStyle ={
            backgroundImage: `url(${mapEnd})`,
            width: '100%',
            backgroundPosition: 'left center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '16px',
            padding: '0.1em 2em'
        }

        return (
            <div className="homeAndDestination">
                <div
                    style={style}
                >
                    <div style={iconOriginStyle}>
                        {postCode}
                    </div>
                </div>
                <div
                    style={style}
                >
                    <div
                        style={iconDestinationStyle}
                    >
                        {
                            clinicAddress.FacilityName + ', ' + 
                            (clinicAddress.Address1 ? clinicAddress.Address1 + ', ' : '') +
                            (clinicAddress.Address2 ? clinicAddress.Address2 + ', ' : '') + 
                            (clinicAddress.Address3 ? clinicAddress.Address3 + ', ' : '') +
                            clinicAddress.Postcode 
                        }
                    </div>
                </div>
            </div>
        )
    }

    const directionMap = () => {
        return (
            <div >
                <div id="mapContainer" className="map-container">
                    <div 
                        className="leftPanel"
                    >
                        <div className="panelTop">
                            <div className="travelMode">
                                <form className="travelMode" name="transport">
                                    <label>
                                        <input type="radio" name="transport" value="DRIVING" defaultChecked={modeType["DRIVING"]}/>
                                        <div className="travel-mode-container" onClick={()=>changeMode('DRIVING')}>
                                            <img src={carIcon} alt="Driving"/>
                                        </div>
                                    </label>
                                    <label>
                                        <input type="radio" name="transport" value="TRANSIT" defaultChecked={modeType["TRANSIT"]}/>
                                        <div className="travel-mode-container" onClick={()=>changeMode('TRANSIT')}>
                                            <img src={trainIcon} alt="Transit"/>
                                        </div>
                                    </label>
                                    <label>
                                        <input type="radio" name="transport" value="WALKING" defaultChecked={modeType["WALKING"]}/>
                                        <div className="travel-mode-container" onClick={()=>changeMode('WALKING')}>
                                            <img src={walkIcon} alt="Walk"/>
                                        </div>
                                    </label>
                                    <label>
                                        <input type="radio" name="transport" value="BICYCLING" defaultChecked={modeType["BICYCLING"]}/>
                                        <div className="travel-mode-container" onClick={()=>changeMode('BICYCLING')}>
                                            <img src={cycleIcon} alt="Cycle"/>
                                        </div>
                                    </label>

                                </form>
                            </div>
                            {clinicAddress ? homeAndDestination() : <></>}
                        </div>
                        
                        {travelMode === 'TRANSIT' ? 
                            <p className="public-transport-warning">
                                Public transport options may vary depending on day and time.
                            </p> : 
                            <p className="public-transport-warning public-transport-warning-hidden">
                                Public transport options may vary depending on day and time.
                            </p>
                        }
                        
                        {
                            travelMode !== 'DRIVING' ? 
                            <p className="custom-warnbox">Use caution - {travelMode !== 'BICYCLING' ? 'walking' : 'cycling'} directions may not reflect real-world conditions</p> :
                            <></>
                        }

                        {directionPanel}
                    </div>
                    <GoogleMap
                        id='directionMap'
                        mapContainerStyle={{
                            height: '600px',
                            width: '100%'
                        }}
                        zoom={3}
                        center={center}
                    >
                        <DirectionsRenderer 
                            options={{
                                directions: direction,
                                suppressMarkers: true,
                                panel: panelSelector.current
                            }}

                            // optional
                            onLoad={() => {
                                makeReRender();
                            }}
                        />
                        <Marker position={center} icon={homeIcon}></Marker>
                        <Marker position={clinicLocation} icon={markerIcon}></Marker>
                    </GoogleMap>                  
                </div>
                
            </div>
        )
    }

    // Function to check empty objects
    const isObjectEmpty = (obj) => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)){
                return false;
            }
        }
        return true;
    }

    return (
        <div 
            className="container"
            style={{
                marginTop: '20px',
                marginBottom: '20px'
            }}
        >
            {loadReady ? (direction ? directionMap() : loadMap()) : <p>No markers</p>}

            {/* <AltServiceText api={api} /> */}
            {!isObjectEmpty(brandingInfo) ? 
                <p className="altServicetext">
                    {brandingInfo["Search Result"]}
                </p> : 
                <></>
            }

        </div>
    );
}

export default Map;