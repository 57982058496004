import React, {useState, useEffect} from 'react';
import ReferralSearch from './ReferralSearch';
import ReferralMap from './ReferralMap';
import ClinicAddress from './ClinicAddress';

const Referral = ({ api, tokenContent, brandingInfo }) => {

    // 0. WR-540: Request is made to make the referral know whether payment card has been used before or not.
    // If payment card has been used before, it will render this component.
    // If payment card has not been used before, it will redirect to the payment component

    const [searchResult, setSearchResult] = useState(null);
    const [postCode, setPostCode] = useState('');
    const [searchAgain, setSearchAgain] = useState(false)

    // 1. Check if clinic has been selected.
    const [selectedClinic, setSelectedClinic] = useState(null);

    // 2. Check if search again button is clicked.
    //    If searchAgain is true, it will set selectedClinic to null and
    //    this will swap ClinicAddress to ReferralSearch.
    useEffect(()=>{
        if(searchAgain !== false){
            setSelectedClinic(null);
            setSearchAgain(false)
        }
    },[searchAgain]);

    /* Moved code from Map.jsx */

    // State to hold loader status for "Confirm Your Clinic" button.
    const [isClinicConfirmLoading, setIsClinicConfirmLoading] = useState(false)

    // State to hold error message under the "Confirm Your Clinic" button.
    const [confirmClinicErrorMessage, setConfirmClinicErrorMessage] = useState("")

    // Redirecting to feedback page after making an API call
    const apiMakeReferral = (token, facilityID) => {
        const params = {
            "Token": token,
            "FacilityID" : facilityID
        }
        api.transact("MakeReferral", params)
            .then(r => {
                const urlForFeedBack = '../feedback/' 
                    + '?Token=' + token 
                    + '&FacilityID=' + facilityID; 
                window.location.replace(urlForFeedBack);
                setIsClinicConfirmLoading(false);
            })
            .catch(error => {
                setConfirmClinicErrorMessage(error.message);
                setIsClinicConfirmLoading(false);
            });
    }
    
    // For ticket WR-396, This map component needs to now redirect the user to the payment page
    // and the payment page will call "MakeReferral" api endpoint when payment has been successful.
    // This function will redirect the browser to payment function with the url parameters of token and FacilityID
    // which is needed to call the "MakeReferral" endpoint.
    const redirectToPayment = (token, facilityID, isPaymentCardRequired) => {
        
        if(isPaymentCardRequired){
             // When "isPaymentCardRequired" is false, it means valid card hasn't been found
            // and the browser will be directed to payment page with facility ID.
            setIsClinicConfirmLoading(false)
            const urlForPayment = '../payment/' 
                + '?Token=' + token 
                + '&FacilityID=' + facilityID;
            window.location.replace(urlForPayment);
        } else {
            // When "isPaymentCardRequired" is true, it means valid card has been found
            // and its okay to make "MakeReferral" api request, and then the browser
            // will be directed to the "feedback" page.
            apiMakeReferral(token,facilityID);
        } 
    } 

    // When Confirm Clinic is clicked, "IsPaymentCardRequired" api endpoint will be called 
    // with the "caseid" in param.
    const confirmClinicHandler = (facilityID) => {

        /* 
            brandingInfo.PaymentCardRequired flags whether we should
            check if the user has a valid payment card on file.
            In later API, IsPaymentCardRequiredNew will be returned
            which will have done the PaymentCardRequired check for us.
            We are being backward compatible here.
        */
        if(brandingInfo.PaymentCardRequired === "true"){
            const params = {
                "caseid": tokenContent.CaseID
            }
            api.transact("IsPaymentCardRequired", params)
            .then(r => r.apiResult)
            .then(r=>{
                // backward compatible with earlier API version
                const isPaymentCardRequired = Object.hasOwn(r, 'IsPaymentCardRequiredNew')?
                    r.IsPaymentCardRequiredNew: true; // with the old API, we assume that payment is required
                redirectToPayment(api.getToken(), facilityID, isPaymentCardRequired);
            })
            .catch(e=>{
                setIsClinicConfirmLoading(false)
            })
        }else if(brandingInfo.PaymentCardRequired === "false"){
            apiMakeReferral(api.getToken(), facilityID)
        }
    }

    // This function will be triggered when confirm clinic button is clicked.
    const selectClinic = (e, facilityID) => {
        e.preventDefault();
        setConfirmClinicErrorMessage("");
        setIsClinicConfirmLoading(true);
        confirmClinicHandler(facilityID);
    }

    return (
        <>
            <div style={{
                paddingTop: '55px'
            }}>
                {/* If selectedClinic isn't null, the page should display the selected clinic.
                    Otherwise, it should display the ReferralSearch component.
                */}
                {selectedClinic === null ? <ReferralSearch 
                    api={api} 
                    setSearchResult={setSearchResult}
                    postCode={postCode} 
                    setPostCode={setPostCode}
                    clientID={tokenContent.UserID} 
                    brandingInfo={brandingInfo}
                    confirmClinicHandler={confirmClinicHandler}
                    /> :
                    <ClinicAddress 
                        api={api} 
                        selectedClinic={selectedClinic} 
                        setSearchAgain={setSearchAgain}
                        selectClinic={selectClinic}
                        confirmClinicErrorMessage={confirmClinicErrorMessage}
                    />
                }

                {searchResult ? 
                    <ReferralMap
                        api={api}
                        searchAgain={searchAgain} 
                        setSelectedClinic={setSelectedClinic} 
                        postCode={postCode} 
                        searchResult={searchResult}
                        brandingInfo={brandingInfo} 
                        setIsClinicConfirmLoading={setIsClinicConfirmLoading}
                        isClinicConfirmLoading={isClinicConfirmLoading}
                        setConfirmClinicErrorMessage={setConfirmClinicErrorMessage}
                        confirmClinicErrorMessage={confirmClinicErrorMessage}
                        confirmClinicHandler={confirmClinicHandler}
                        selectClinic={selectClinic}
                        /> : <></>
                }
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
        </>
    )
}

export default Referral