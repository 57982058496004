import React, {useState, useCallback} from 'react';
import PhoneNumber from '../PhoneNumber';
import {LoadingIndicator} from '../../lib/iprs-react-library/src/index';

// const demoPWPracticeSearchResponseJson = {
//     "Status": "OK",
//     "Text": "",
//     "Result": {
//         "Home": {
//             "Longitude": -2.6414635,
//             "Latitude": 53.707806
//         },
//         "ClosestIndex": 0,
//         "FacilityList": [
//             {
//                 "RN": 0,
//                 "FacilityName": "OPM - OPM Test Provider One",
//                 "pkFacilityID": 63036,
//                 "FacilityID": 63036,
//                 "Address1": "P1testvenue Pr7 7na",
//                 "Address2": "",
//                 "Address3": null,
//                 "Town": "P1testvenue  PR7 7NA",
//                 "Country": null,
//                 "County": null,
//                 "Postcode": "PR7 7NA",
//                 "DistanceToPostcode": 2.48,
//                 "Latitude": 0,
//                 "Longitude": 0,
//                 "Colour": "34ebde",
//                 "SearchDistance": 0.0,
//                 "PracticeRankingId": 8,
//                 "PracticeRankingDescription": "OPM",
//                 "Fee": 0.0,
//                 "OpeningHours": "9-5",
//                 "DDACompliant": "Yes",
//                 "Priority": 1,
//                 "CarParking": "Yes",
//                 "Fees": null,
//                 "ElectronicAddress": null,
//                 "TelephoneNumber": "",
//                 "Modalities": null,
//                 "FromPostcode": "PR6 7EN",
//                 "From": "Home",
//                 "GoogleDistance": 2.8601787067990605,
//                 "Route": {
//                     "summary": "B5256",
//                     "leg": {
//                         "step": [
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7076820",
//                                     "lng": "-2.6415326"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7079095",
//                                     "lng": "-2.6418148"
//                                 },
//                                 "polyline": {
//                                     "points": "_xxfIp|bOg@t@E@"
//                                 },
//                                 "duration": {
//                                     "value": "3",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Head <b>northwest</b> toward <b>Library Rd</b>",
//                                 "distance": {
//                                     "value": "31",
//                                     "text": "31 m"
//                                 }
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7079095",
//                                     "lng": "-2.6418148"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7073935",
//                                     "lng": "-2.6413421"
//                                 },
//                                 "polyline": {
//                                     "points": "myxfIh~bO?EMgA?G?G@E@EFMJSHMHMDGBAD?@@BBFFTd@T^"
//                                 },
//                                 "duration": {
//                                     "value": "25",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Turn <b>right</b> onto <b>Library Rd</b>",
//                                 "distance": {
//                                     "value": "120",
//                                     "text": "0.1 km"
//                                 },
//                                 "maneuver": "turn-right"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7073935",
//                                     "lng": "-2.6413421"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7067932",
//                                     "lng": "-2.6420606"
//                                 },
//                                 "polyline": {
//                                     "points": "evxfIj{bONGD?F?D?H@FFHFBD@DLf@Nf@Rh@"
//                                 },
//                                 "duration": {
//                                     "value": "32",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Turn <b>left</b> to stay on <b>Library Rd</b>",
//                                 "distance": {
//                                     "value": "92",
//                                     "text": "92 m"
//                                 },
//                                 "maneuver": "turn-left"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7067932",
//                                     "lng": "-2.6420606"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7064283",
//                                     "lng": "-2.6417722"
//                                 },
//                                 "polyline": {
//                                     "points": "mrxfIz_cOTWFEXQNI"
//                                 },
//                                 "duration": {
//                                     "value": "26",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Turn <b>left</b> to stay on <b>Library Rd</b>",
//                                 "distance": {
//                                     "value": "45",
//                                     "text": "45 m"
//                                 },
//                                 "maneuver": "turn-left"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7064283",
//                                     "lng": "-2.6417722"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.6963234",
//                                     "lng": "-2.6729802"
//                                 },
//                                 "polyline": {
//                                     "points": "epxfI`~bOThAXnAZjAZlALd@BAB@B@BBBF@F?H?BAFf@~@r@pAj@hAZl@NXHRN`@J\\HVDTDRHb@BPBXD\\B`@Fx@Fj@BRHh@Lh@@DHPFNT`@LRLPXb@HJ\\b@JLl@x@V\\^h@?B\\j@Vf@@BNX`@v@Xh@PZFLJPLXP^Vn@BHJXFP\\fAFRFVPz@?@Nv@@FJj@?@H\\@DNd@LXDHFJJPBDJLJPHJPZHNTt@Lv@Lt@Nv@@FJp@Fl@?JLtBFhA?BBT@T@LD`@DV@N@FDZ@DDTFZ?BPt@Nt@Pr@XhAH^BLLf@@FNd@@BHTLVLTHJFHJL@@HHTPJHTRLNJL?BJPJNFNJ\\BH@?B?@?@@B@?B@B@B?D?B?BABAB?@A@A@D^D\\BT?BDXBZ?@Fx@ZnDNrBd@|F\\`Eb@dEFp@j@vGNjBJnA@LJhAFx@Df@Dj@ThEHdBDF@D@B@X?L@p@"
//                                 },
//                                 "duration": {
//                                     "value": "248",
//                                     "text": "4 mins"
//                                 },
//                                 "html_instructions": "Turn <b>right</b> onto <b>Clayton Green Rd</b>/<wbr/><b>B5256</b>",
//                                 "distance": {
//                                     "value": "2437",
//                                     "text": "2.4 km"
//                                 },
//                                 "maneuver": "turn-right"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.6963234",
//                                     "lng": "-2.6729802"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.6877072",
//                                     "lng": "-2.6758571"
//                                 },
//                                 "polyline": {
//                                     "points": "_qvfIbaiO\\BL?N?@?N@F@H@RDJB`@NDBJDnAj@PFNFJBFBFB\\JRDB?F@F@PB@?F@D@P@R@J@J@HBF@JBJDJFJF^XDBPN\\V?@NJHFHFFBVJF@HDD@h@N\\HXFTBVDx@N~@Nr@JB?p@HD?NBt@LL@B?NBRBJ@rARh@Fx@HRBx@JPDJ@HBHBTF`@LjBh@"
//                                 },
//                                 "duration": {
//                                     "value": "98",
//                                     "text": "2 mins"
//                                 },
//                                 "html_instructions": "Turn <b>left</b> onto <b>Wigan Rd</b>/<wbr/><b>A49</b>",
//                                 "distance": {
//                                     "value": "982",
//                                     "text": "1.0 km"
//                                 },
//                                 "maneuver": "turn-left"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.6877072",
//                                     "lng": "-2.6758571"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.6873097",
//                                     "lng": "-2.6691276"
//                                 },
//                                 "polyline": {
//                                     "points": "e{tfIbsiO@aAFmJB}ABgAVqJBgABe@Ba@Dc@Da@Hg@Hi@EqA"
//                                 },
//                                 "duration": {
//                                     "value": "46",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Turn <b>left</b> onto <b>Dawson Ln</b>/<wbr/><b>B5248</b>",
//                                 "distance": {
//                                     "value": "448",
//                                     "text": "0.4 km"
//                                 },
//                                 "maneuver": "turn-left"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.6873097",
//                                     "lng": "-2.6691276"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.6864266",
//                                     "lng": "-2.6688385"
//                                 },
//                                 "polyline": {
//                                     "points": "uxtfI`ihOAAAECCAG?E?AAE?E@G?A?C@GBC@EBCBABABAD@@?@@B@@B@@NFHBHDFFB@n@DB?HGFCHCDCHA"
//                                 },
//                                 "duration": {
//                                     "value": "31",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "At the roundabout, take the <b>2nd</b> exit",
//                                 "distance": {
//                                     "value": "131",
//                                     "text": "0.1 km"
//                                 },
//                                 "maneuver": "roundabout-left"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.6864266",
//                                     "lng": "-2.6688385"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.6862588",
//                                     "lng": "-2.6724371"
//                                 },
//                                 "polyline": {
//                                     "points": "estfIfghO@A@A@A@??A@?@?@?@?@?@??@@?@@@??@@@@@?@@??@?@@??@?@?@@@?@?@?@?@@@?@?@?@A@?B?@?@?@?@A@?@A@?@?@A??@?@A??@A??@A?ALAHETCJAJAXAV?RAPCtBC~@?N?L?N@L@J@JBRBRJp@BPBRBT@T?R?P?T"
//                                 },
//                                 "duration": {
//                                     "value": "46",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "At the roundabout, take the <b>3rd</b> exit onto <b>Eaton Ave</b>",
//                                 "distance": {
//                                     "value": "264",
//                                     "text": "0.3 km"
//                                 },
//                                 "maneuver": "roundabout-left"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.6862588",
//                                     "lng": "-2.6724371"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.6867160",
//                                     "lng": "-2.6722383"
//                                 },
//                                 "polyline": {
//                                     "points": "crtfIv}hOYAG?C?CAEAIIa@W"
//                                 },
//                                 "duration": {
//                                     "value": "21",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Turn <b>right</b><div style=\"font-size:0.9em\">Destination will be on the left</div>",
//                                 "distance": {
//                                     "value": "53",
//                                     "text": "53 m"
//                                 },
//                                 "maneuver": "turn-right"
//                             }
//                         ],
//                         "duration": {
//                             "value": "576",
//                             "text": "10 mins"
//                         },
//                         "distance": {
//                             "value": "4603",
//                             "text": "4.6 km"
//                         },
//                         "start_location": {
//                             "lat": "53.7076820",
//                             "lng": "-2.6415326"
//                         },
//                         "end_location": {
//                             "lat": "53.6867160",
//                             "lng": "-2.6722383"
//                         },
//                         "start_address": "Clayton-le-Woods, Chorley PR6 7EN, UK",
//                         "end_address": "Eaton Ave, Buckshaw Village, Chorley PR7 7NA, UK"
//                     },
//                     "copyrights": "Map data ©2024 Google",
//                     "overview_polyline": {
//                         "points": "_xxfIp|bOm@v@?EMoA@M^u@NUHADD\\l@T^NGL?N@PNDJ\\nARh@TW`@WNIThAt@zCh@rBF?FDDN?LAFf@~@~AzCj@fAXt@Tt@X~ATrCJ~@VrAJV\\p@~@tAnBhC^l@t@rAlA~Br@tAx@rBt@dCz@nE\\~@LTf@v@Zf@^dAl@lDR~AL`CLxB^vCXtAdArEb@bBJXZl@^d@`Ax@d@r@R^Nf@H@FLAPEH`@hEnBbVj@vFz@bK`@`FJrA^nHFLB\\@~@j@Bh@B\\Fl@RrB|@j@R|@Rb@Fv@Fh@Jb@TtAdAXTPJ^LvA`@`C`@rBZt@HxARr@H|BZlAL`BVlDbAHoLFeDb@aO^wCGsAEICU@SJUPCHFd@RJHr@D`@SNGHAFBDFFTE\\EFEVI`@Cd@GrDClBHx@VjBDpA?TYAK?ICk@a@"
//                     },
//                     "bounds": {
//                         "southwest": {
//                             "lat": "53.6862448",
//                             "lng": "-2.6758571"
//                         },
//                         "northeast": {
//                             "lat": "53.7079840",
//                             "lng": "-2.6409195"
//                         }
//                     }
//                 },
//                 "ModalityList": [],
//                 "WebAddress": ""
//             },
//             {
//                 "RN": 4,
//                 "FacilityName": "Summit Physiotherapy Ltd - Chorley",
//                 "pkFacilityID": 51178,
//                 "FacilityID": 51178,
//                 "Address1": "David Lloyd Leisure",
//                 "Address2": "Moss Lane",
//                 "Address3": "Whittle-Le-Woods",
//                 "Town": "Chorley",
//                 "Country": "UK",
//                 "County": "Lancashire",
//                 "Postcode": "PR6 8AB",
//                 "DistanceToPostcode": 3.0,
//                 "Latitude": 53.6778931428244,
//                 "Longitude": -2.62096490417625,
//                 "Colour": "009900",
//                 "SearchDistance": 10.0,
//                 "PracticeRankingId": 1,
//                 "PracticeRankingDescription": "PPS",
//                 "Fee": 150.0,
//                 "OpeningHours": null,
//                 "DDACompliant": null,
//                 "Priority": 0,
//                 "CarParking": null,
//                 "Fees": null,
//                 "ElectronicAddress": null,
//                 "TelephoneNumber": null,
//                 "Modalities": "1; 38; ",
//                 "FromPostcode": "PR6 7EN",
//                 "From": "Home",
//                 "GoogleDistance": 3.1211552561919795,
//                 "Route": {
//                     "summary": "Preston Rd/A6",
//                     "leg": {
//                         "step": [
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7076820",
//                                     "lng": "-2.6415326"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7079095",
//                                     "lng": "-2.6418148"
//                                 },
//                                 "polyline": {
//                                     "points": "_xxfIp|bOg@t@E@"
//                                 },
//                                 "duration": {
//                                     "value": "3",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Head <b>northwest</b> toward <b>Library Rd</b>",
//                                 "distance": {
//                                     "value": "31",
//                                     "text": "31 m"
//                                 }
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7079095",
//                                     "lng": "-2.6418148"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7073935",
//                                     "lng": "-2.6413421"
//                                 },
//                                 "polyline": {
//                                     "points": "myxfIh~bO?EMgA?G?G@E@EFMJSHMHMDGBAD?@@BBFFTd@T^"
//                                 },
//                                 "duration": {
//                                     "value": "25",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Turn <b>right</b> onto <b>Library Rd</b>",
//                                 "distance": {
//                                     "value": "120",
//                                     "text": "0.1 km"
//                                 },
//                                 "maneuver": "turn-right"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7073935",
//                                     "lng": "-2.6413421"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7067932",
//                                     "lng": "-2.6420606"
//                                 },
//                                 "polyline": {
//                                     "points": "evxfIj{bONGD?F?D?H@FFHFBD@DLf@Nf@Rh@"
//                                 },
//                                 "duration": {
//                                     "value": "32",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Turn <b>left</b> to stay on <b>Library Rd</b>",
//                                 "distance": {
//                                     "value": "92",
//                                     "text": "92 m"
//                                 },
//                                 "maneuver": "turn-left"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7067932",
//                                     "lng": "-2.6420606"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7064283",
//                                     "lng": "-2.6417722"
//                                 },
//                                 "polyline": {
//                                     "points": "mrxfIz_cOTWFEXQNI"
//                                 },
//                                 "duration": {
//                                     "value": "26",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Turn <b>left</b> to stay on <b>Library Rd</b>",
//                                 "distance": {
//                                     "value": "45",
//                                     "text": "45 m"
//                                 },
//                                 "maneuver": "turn-left"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7064283",
//                                     "lng": "-2.6417722"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7069244",
//                                     "lng": "-2.6396535"
//                                 },
//                                 "polyline": {
//                                     "points": "epxfI`~bOy@mFGUM_AC[E[Gk@"
//                                 },
//                                 "duration": {
//                                     "value": "43",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Turn <b>left</b> onto <b>Clayton Green Rd</b>/<wbr/><b>B5256</b>",
//                                 "distance": {
//                                     "value": "150",
//                                     "text": "0.2 km"
//                                 },
//                                 "maneuver": "turn-left"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7069244",
//                                     "lng": "-2.6396535"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.6778500",
//                                     "lng": "-2.6355261"
//                                 },
//                                 "polyline": {
//                                     "points": "gsxfIxpbOECAAAG?E?EAKFI@A@AD?B?B?@@@BR?HBJ?H@H@FBPFB?f@J~@T~A\\TAZ?NA\\EVC`@Kv@KVELAD?F?PB`AAhACt@@jA?jA?V?T?J?J?D?J?D?l@?|A?D?F?P?l@AV?n@EL?NAn@Ej@IvASPCb@ITCTEj@Ev@M^El@IzAYXEZERCTEl@KhBWx@Mb@G\\GRE`@GHA`@Ex@K`@An@@f@F|ALT@j@Fl@Dv@HnAL`@D~@FVB^DvALv@FhBNf@DdAJ`@DH@TB|BRpCPv@D@@dAD^BJ?D?R@N@N?d@BV@h@B`@BD?bBFl@BB@d@@^@J?~@CRC@?NCNETENINGLGTOXQxDeCn@a@`Am@|A}@|CuAtCqAVKl@UPINElDqA\\MtAe@\\Mh@STMLKJKNW"
//                                 },
//                                 "duration": {
//                                     "value": "247",
//                                     "text": "4 mins"
//                                 },
//                                 "html_instructions": "At the roundabout, take the <b>3rd</b> exit onto <b>Preston Rd</b>/<wbr/><b>A6</b>",
//                                 "distance": {
//                                     "value": "3316",
//                                     "text": "3.3 km"
//                                 },
//                                 "maneuver": "roundabout-left"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.6778500",
//                                     "lng": "-2.6355261"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.6762605",
//                                     "lng": "-2.6351410"
//                                 },
//                                 "polyline": {
//                                     "points": "q}rfI`waOAIAG?G?E?K@E@GBC@C@ADCB@DBBBB@@B@B@B?D@D?D?HTDL?D@D?D?HANCXKVKNEZIZGjAK"
//                                 },
//                                 "duration": {
//                                     "value": "20",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "At the roundabout, take the <b>2nd</b> exit and stay on <b>Preston Rd</b>/<wbr/><b>A6</b>",
//                                 "distance": {
//                                     "value": "211",
//                                     "text": "0.2 km"
//                                 },
//                                 "maneuver": "roundabout-left"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.6762605",
//                                     "lng": "-2.6351410"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.6779512",
//                                     "lng": "-2.6211797"
//                                 },
//                                 "polyline": {
//                                     "points": "ssrfIrtaOGi@COAICSG]CK[oB[kBGi@C_@?_@@M@MD[FY?ARq@FYNq@H]Ns@HYLu@D]?ADWJw@FoADwA?o@BwB@sA?{A@O?E?Y?yA?u@BsB?K?_A@eAAY?W?CAm@Ck@Cm@MiACSGi@EUM{@AGYyAO_AEKGYM]CGQ]MYIQCGMQKIMIa@Uo@a@MGm@_@IE_@Q"
//                                 },
//                                 "duration": {
//                                     "value": "85",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Turn <b>left</b> onto <b>Moss Ln</b>/<wbr/><b>B6229</b><div style=\"font-size:0.9em\">Destination will be on the right</div>",
//                                 "distance": {
//                                     "value": "1058",
//                                     "text": "1.1 km"
//                                 },
//                                 "maneuver": "turn-left"
//                             }
//                         ],
//                         "duration": {
//                             "value": "481",
//                             "text": "8 mins"
//                         },
//                         "distance": {
//                             "value": "5023",
//                             "text": "5.0 km"
//                         },
//                         "start_location": {
//                             "lat": "53.7076820",
//                             "lng": "-2.6415326"
//                         },
//                         "end_location": {
//                             "lat": "53.6779512",
//                             "lng": "-2.6211797"
//                         },
//                         "start_address": "Clayton-le-Woods, Chorley PR6 7EN, UK",
//                         "end_address": "Whittle-le-Woods, Chorley PR6 8AB, UK"
//                     },
//                     "copyrights": "Map data ©2024 Google",
//                     "overview_polyline": {
//                         "points": "_xxfIp|bOm@v@?EMoA@M^u@NUHADD\\l@T^NGL?N@PNDJ\\nARh@TW`@WNIy@mFUuAIw@Gk@ECCI?KAKFIBCN@@BR?TBl@NjE~@p@Al@Gx@OnAQRAXBjCE`C@dC?|D?fCG\\AzAObDe@xC_@~Do@`Em@nCc@fC[pA?dCTfDXxJz@xFf@nHj@tETpHXtADrAGPCd@K^Qb@WrEwCpBoA|A}@|CuAlD}A~@_@pHkCfAa@b@YZc@CQ@_@HQHAHFHL@Zn@FNAh@Of@Qv@QjAKGi@EYk@mDc@uCC_AB[Lu@j@_Cp@aDVoBLgDBgDBeEBiMEuBQwBK}@o@sDUkAUw@m@qA]c@mBiAw@e@_@Q"
//                     },
//                     "bounds": {
//                         "southwest": {
//                             "lat": "53.6759384",
//                             "lng": "-2.6420606"
//                         },
//                         "northeast": {
//                             "lat": "53.7079840",
//                             "lng": "-2.6211797"
//                         }
//                     }
//                 },
//                 "ModalityList": [
//                     {
//                         "ROW_NUMBER": 1,
//                         "RowVersion": "AAAAAAdF7M0=",
//                         "Available": true,
//                         "ModalityDescription": "Physiotherapy",
//                         "FacilityID": 51178,
//                         "ModalityID": 1
//                     },
//                     {
//                         "ROW_NUMBER": 2,
//                         "RowVersion": "AAAAAAg8NmU=",
//                         "Available": true,
//                         "ModalityDescription": "CV19 Ready",
//                         "FacilityID": 51178,
//                         "ModalityID": 38
//                     }
//                 ],
//                 "WebAddress": ""
//             },
//             {
//                 "RN": 3,
//                 "FacilityName": "Total Physiotherapy - Blackburn",
//                 "pkFacilityID": 48724,
//                 "FacilityID": 48724,
//                 "Address1": "343/345 Preston Old Road",
//                 "Address2": "Feniscowles",
//                 "Address3": null,
//                 "Town": "Blackburn",
//                 "Country": "UK",
//                 "County": null,
//                 "Postcode": "BB2 5LJ",
//                 "DistanceToPostcode": 6.0,
//                 "Latitude": 53.73497407,
//                 "Longitude": -2.517677099,
//                 "Colour": "009900",
//                 "SearchDistance": 10.0,
//                 "PracticeRankingId": 1,
//                 "PracticeRankingDescription": "PPS",
//                 "Fee": 135.5,
//                 "OpeningHours": null,
//                 "DDACompliant": null,
//                 "Priority": 0,
//                 "CarParking": null,
//                 "Fees": null,
//                 "ElectronicAddress": null,
//                 "TelephoneNumber": null,
//                 "Modalities": "1; 4; 38; ",
//                 "FromPostcode": "PR6 7EN",
//                 "From": "Home",
//                 "GoogleDistance": 6.448606260951695,
//                 "Route": {
//                     "summary": "B5256",
//                     "leg": {
//                         "step": [
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7076820",
//                                     "lng": "-2.6415326"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7079095",
//                                     "lng": "-2.6418148"
//                                 },
//                                 "polyline": {
//                                     "points": "_xxfIp|bOg@t@E@"
//                                 },
//                                 "duration": {
//                                     "value": "3",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Head <b>northwest</b> toward <b>Library Rd</b>",
//                                 "distance": {
//                                     "value": "31",
//                                     "text": "31 m"
//                                 }
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7079095",
//                                     "lng": "-2.6418148"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7073935",
//                                     "lng": "-2.6413421"
//                                 },
//                                 "polyline": {
//                                     "points": "myxfIh~bO?EMgA?G?G@E@EFMJSHMHMDGBAD?@@BBFFTd@T^"
//                                 },
//                                 "duration": {
//                                     "value": "25",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Turn <b>right</b> onto <b>Library Rd</b>",
//                                 "distance": {
//                                     "value": "120",
//                                     "text": "0.1 km"
//                                 },
//                                 "maneuver": "turn-right"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7073935",
//                                     "lng": "-2.6413421"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7067932",
//                                     "lng": "-2.6420606"
//                                 },
//                                 "polyline": {
//                                     "points": "evxfIj{bONGD?F?D?H@FFHFBD@DLf@Nf@Rh@"
//                                 },
//                                 "duration": {
//                                     "value": "32",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Turn <b>left</b> to stay on <b>Library Rd</b>",
//                                 "distance": {
//                                     "value": "92",
//                                     "text": "92 m"
//                                 },
//                                 "maneuver": "turn-left"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7067932",
//                                     "lng": "-2.6420606"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7064283",
//                                     "lng": "-2.6417722"
//                                 },
//                                 "polyline": {
//                                     "points": "mrxfIz_cOTWFEXQNI"
//                                 },
//                                 "duration": {
//                                     "value": "26",
//                                     "text": "1 min"
//                                 },
//                                 "html_instructions": "Turn <b>left</b> to stay on <b>Library Rd</b>",
//                                 "distance": {
//                                     "value": "45",
//                                     "text": "45 m"
//                                 },
//                                 "maneuver": "turn-left"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7064283",
//                                     "lng": "-2.6417722"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7044768",
//                                     "lng": "-2.6328700"
//                                 },
//                                 "polyline": {
//                                     "points": "epxfI`~bOy@mFGUM_AC[E[Gk@ECAAAG?E?EAKFIAgACaAAi@As@?k@AkAAsC?Y?CAuBAwB?W?u@By@Bs@@I@MD]BMBUJc@J]FOBGN]BELUV]LOFGJIJGHEJEJETG@?RCLADAx@CF?fACb@AH?|@@"
//                                 },
//                                 "duration": {
//                                     "value": "100",
//                                     "text": "2 mins"
//                                 },
//                                 "html_instructions": "Turn <b>left</b> onto <b>Clayton Green Rd</b>/<wbr/><b>B5256</b><div style=\"font-size:0.9em\">Go through 1 roundabout</div>",
//                                 "distance": {
//                                     "value": "818",
//                                     "text": "0.8 km"
//                                 },
//                                 "maneuver": "turn-left"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7044768",
//                                     "lng": "-2.6328700"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7240084",
//                                     "lng": "-2.5816569"
//                                 },
//                                 "polyline": {
//                                     "points": "_dxfIlfaO@I@EBEBCDAB@?{@?KA]ASAUCs@GeACc@GkAEe@AQGm@AMYeCCYCYOcAKk@Wy@Sm@EIEKKWQ_@c@o@_@k@c@q@_@w@GKISOWWu@y@oCs@sBc@iAWo@m@{AAGUe@KU[o@IOS[Q[UWSUUSg@i@W[S_@c@}@e@mAQs@Ks@E_A?MA{@?AA{@?GCs@E_AAw@A[EeBIo@?AGYKWCCW_@CC]g@s@eAU[_@_@gAy@}@k@c@YKISO]Yo@o@OQMSIQAAUu@Ks@AEIs@AQEi@Iy@C[C]Go@AKKw@I{@Gu@AC[iEEa@Ky@]{Bg@kCEMi@_BOWUg@Sc@CGSc@GQO[IUMUYg@ACQSSMQIYKOCIIUY[m@e@aACGWm@GQi@qA]w@KWEIEIo@cBq@kAIMQ[S]GIUk@?AUm@GM_@iA[y@[}@Og@IUQc@Oc@OUQ]iAiB[g@OUw@kAeAuBEKWk@EIKc@Kg@SkCAWSyAOeAIm@Kw@K}@KiAC}@EcB?KEo@IwAG{AMsDCc@C{A?]@c@j@aDL{@\\oB@IJm@Ly@|@wDHWLe@T{@BQ@O?Y?QCW?CWcAEQCSMq@AE?AQi@AEKYYi@gAeBO[GSQo@[aBEYI[ACOo@EM[qAEKIUIUACSk@EIMWEGIOOOAAa@[cAu@MKq@e@e@c@USCCOQKIACKISQ_@MEC[MC?cA_@iA_@O?SCQCYEOE"
//                                 },
//                                 "duration": {
//                                     "value": "305",
//                                     "text": "5 mins"
//                                 },
//                                 "html_instructions": "At the roundabout, take the <b>1st</b> exit onto <b>Sandy Ln</b>/<wbr/><b>B5256</b><div style=\"font-size:0.9em\">Continue to follow B5256</div><div style=\"font-size:0.9em\">Parts of this road may be closed at certain times or days</div>",
//                                 "distance": {
//                                     "value": "4420",
//                                     "text": "4.4 km"
//                                 },
//                                 "maneuver": "roundabout-left"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7240084",
//                                     "lng": "-2.5816569"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7211955",
//                                     "lng": "-2.5547585"
//                                 },
//                                 "polyline": {
//                                     "points": "a~{fIjfwNBc@@EDe@AYKaCQ_BEa@KsAGaAG_C?ICaB?EAw@AwA?}@A{@@m@?EC{@A}@?{@?a@Ai@?q@@s@Bq@HkAJ}@Fw@Jy@VqC\\}CTqBJy@`@oDBw@@}@BeA?q@?yE?O?{C?Q@k@?KDaALcBH_Av@wHPaC\\aFHqAHkANuBDi@Hg@TeAd@yBLi@`@oB@GFWF]\\kBLy@ZoBJo@BGBOHi@Hs@?CH}@NoB?A"
//                                 },
//                                 "duration": {
//                                     "value": "119",
//                                     "text": "2 mins"
//                                 },
//                                 "html_instructions": "Turn <b>right</b> onto <b>Riley Green Switch Rd</b>/<wbr/><b>A675</b><div style=\"font-size:0.9em\">Continue to follow Riley Green Switch Rd</div>",
//                                 "distance": {
//                                     "value": "1834",
//                                     "text": "1.8 km"
//                                 },
//                                 "maneuver": "turn-right"
//                             },
//                             {
//                                 "travel_mode": "DRIVING",
//                                 "start_location": {
//                                     "lat": "53.7211955",
//                                     "lng": "-2.5547585"
//                                 },
//                                 "end_location": {
//                                     "lat": "53.7350240",
//                                     "lng": "-2.5180188"
//                                 },
//                                 "polyline": {
//                                     "points": "ol{fIf~qNFcD?mA?e@IqAC]K_BWgBK{@m@eDc@yCi@aDO}@WaCm@qFMmAKy@Eo@QaBYcCUaBKw@M}@[kBKm@O_AAKI_@CIOk@[y@EGQ_@Ua@SUIGECMIMEG?IAWEc@EIASEEAICC?MGECKEUOCCWSCCIGQQCA_@]GIGG[g@KQKSO_@Y{@K_@IU?@A?A??AA?A??A?AA??A?A?AA??A?A?A?A?A@??A?A@??AUg@CIIQAEIWK]YiAg@}Bc@mCSsBC[Eg@CSGi@Eg@E_@C[c@kDSwAE_@ACQoAKy@[yBAMGa@_@sCU_Bk@kDs@qCq@iCGY{@gDa@kBCKm@yCAI]aBKc@W_A{@eCiB{DAEi@eAEISc@c@gAEOQi@CIk@sBACOi@_@y@[o@Wk@IOwAsCU[W]_@o@g@o@e@q@i@o@?@A??@A?A?A??AA??AA??A?AAA?A?A?A@AOKi@a@WQgBi@SG"
//                                 },
//                                 "duration": {
//                                     "value": "264",
//                                     "text": "4 mins"
//                                 },
//                                 "html_instructions": "Continue onto <b>Moulden Brow</b>/<wbr/><b>A674</b><div style=\"font-size:0.9em\">Destination will be on the right</div>",
//                                 "distance": {
//                                     "value": "3018",
//                                     "text": "3.0 km"
//                                 }
//                             }
//                         ],
//                         "duration": {
//                             "value": "874",
//                             "text": "15 mins"
//                         },
//                         "distance": {
//                             "value": "10378",
//                             "text": "10.4 km"
//                         },
//                         "start_location": {
//                             "lat": "53.7076820",
//                             "lng": "-2.6415326"
//                         },
//                         "end_location": {
//                             "lat": "53.7350240",
//                             "lng": "-2.5180188"
//                         },
//                         "start_address": "Clayton-le-Woods, Chorley PR6 7EN, UK",
//                         "end_address": "Preston Old Rd, Blackburn BB2 5LJ, UK"
//                     },
//                     "copyrights": "Map data ©2024 Google",
//                     "overview_polyline": {
//                         "points": "_xxfIp|bOm@v@?EMoA@M^u@NUHADD\\l@T^NGL?N@PNDJ\\nARh@TW`@WNIy@mFUuAIw@Gk@ECCI?KAKFIEiCEuECgHAoCBoBLiBFc@VaA^{@d@s@TWl@]v@QtAGjBEfA@BOFIH??gAI{BY{Ee@sEGs@[oBw@}B]w@cA{AcAiBQ_@g@mAmBcGkB}EgA{Be@w@i@m@}@}@k@{@iAkC]gBGkCKwDCsAEeBIo@G[O[mBqCu@{@eCeBaBmA_AaAWe@Ww@My@[iDQuB]iD]mEQ{A]{Bg@kCo@mB}@kBu@gB{@uAe@Wi@O_@c@aAoBmAyCu@cBo@cBq@kA[i@[g@Um@yA_Eu@{Ba@gAa@s@mDsFiBwDWkAUcDy@eGWgCIaDWoFQwECyB@c@j@aDj@kDLw@Ly@|@wDV}@XmA@i@Ci@a@mBOw@Qk@M_@aBoCWo@m@qCa@iBq@aCe@oAS_@Y_@uB_BwAiAYWi@i@s@_@a@QgA_@iA_@O?e@Gi@KDi@B_AKaCQ_BQuBOaECkBCsEGqJ@eBL}Bv@aI`ByNHmF?wK@w@ReD`AwJn@cJh@}H^mBr@cDr@mDrAeIZuBXsDFqF?e@MoBK_BWgBy@aFmA{HO}@WaC{@_IQiBk@eFkAcIg@yCSu@a@aAg@aA]]SMUEoAOg@Ku@a@gB_Bc@o@We@i@{AUu@?@A?A?AACGAI@E@Ac@cAq@eCg@}Bc@mCWoCWmCaA_Ie@mDeA}HaAkGeB{GiByHyAiHW_A{@eCkBaEo@oAw@kBgAwDQm@{@iBa@{@wAsCU[w@mAmAaBi@o@A@C@CCCI@CqA_A{Bq@"
//                     },
//                     "bounds": {
//                         "southwest": {
//                             "lat": "53.7043677",
//                             "lng": "-2.6420606"
//                         },
//                         "northeast": {
//                             "lat": "53.7350240",
//                             "lng": "-2.5180188"
//                         }
//                     }
//                 },
//                 "ModalityList": [
//                     {
//                         "ROW_NUMBER": 1,
//                         "RowVersion": "AAAAAAakTI8=",
//                         "Available": true,
//                         "ModalityDescription": "Physiotherapy",
//                         "FacilityID": 48724,
//                         "ModalityID": 1
//                     },
//                     {
//                         "ROW_NUMBER": 2,
//                         "RowVersion": "AAAAAAakTJA=",
//                         "Available": true,
//                         "ModalityDescription": "Podiatry",
//                         "FacilityID": 48724,
//                         "ModalityID": 4
//                     },
//                     {
//                         "ROW_NUMBER": 3,
//                         "RowVersion": "AAAAAAhVMkQ=",
//                         "Available": true,
//                         "ModalityDescription": "CV19 Ready",
//                         "FacilityID": 48724,
//                         "ModalityID": 38
//                     }
//                 ],
//                 "WebAddress": ""
//             }
//         ]
//     },
//     "Metadata": {},
//     "RecordCount": 0
// }

const api_key = process.env.REACT_APP_GOOGLE_MAP_API_KEY

const ReferralSearch = ({postCode, setPostCode, api, setSearchResult, brandingInfo}) => {
    const [error, setError] = useState('');
    const [noResult, setNoResult] = useState(false);
    const [searchStatus, setSearchStatus] = useState(false);

    const fetchCoordinateFromGoogleMap = useCallback(async postcode => {
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${postcode}&key=${api_key}`;

        try {
            const response = await fetch(geocodeUrl);
            const data = await response.json();

            if (data.results.length > 0) {
                const { lat, lng } = data.results[0].geometry.location;
                return { lat, lng };
            } else {
                throw new Error("No results found for the given postcode", error);
            }
        } catch (error) {
            throw error;
        }
    }, []);

    const searchRequest = async () => {
        setSearchResult(null)
        setError('')
        setSearchStatus(true)

        const params = {
            "Postcode": postCode,
            "Flag": "Self Referral"
        }

        const gpsCoordinateHandler = async (arr) => {
            // Use Promise.all to resolve all promises in the array
            const newArr = await Promise.all(
                arr.map(async (o) => {
                    if (o.Latitude === 0 && o.Longitude === 0) {
                        try {
                            const coordinatesFromGoogleMapsAPI = await fetchCoordinateFromGoogleMap(o.Postcode);
                            o.Latitude = coordinatesFromGoogleMapsAPI.lat;
                            o.Longitude = coordinatesFromGoogleMapsAPI.lng;
                        } catch (error) {
                            console.error("Error fetching coordinates for postcode:", o.Postcode, error);
                        }
                    }
                    return o;
                })
            );
            return newArr;
        };

        
        try {
            const r = await api.transact("PWPracticeSearch", params);

            // Check if FacilityList is empty
            if (r.apiResult.FacilityList.length < 1) {
                setNoResult(true);
                return;  // Return early to prevent further code execution if no results are found
            } 
        
            // No need for Result, since we're working directly with apiResult.FacilityList
            let processedApiResponse = r.apiResult;
            
            // Process FacilityList using gpsCoordinateHandler
            const processedFacilityList = await gpsCoordinateHandler(r.apiResult.FacilityList);
            
            // Update the FacilityList in the API response
            processedApiResponse.FacilityList = processedFacilityList;
        
            // Update state with the fully processed facility list
            setSearchResult(processedApiResponse);
            setNoResult(false);
        
        } catch (error) {
            setError(error.message);
        } finally {
            setSearchStatus(false);
        }
    }

    return (
        <>
            <div className="typography">
                    {!noResult 
                        ?   <h1>Search for a clinic near you!</h1>
                        :   <>
                                <h1 style={{fontSize: '3.2rem'}}>We’re sorry, there are no clinics near you</h1>
                                <p style={{
                                    fontSize: '1.6rem', 
                                    margin: '0 auto',
                                    marginBottom: '4em',
                                    maxWidth: '664px'
                                }}>We are sorry that we have not been able to display a clinic local to you. 
                                   You have the option to search using an alternative postcode below or IPRS Health has 
                                   several support clinics that may be able to assist you but are not shown within the 
                                   search, to find out more please contact <PhoneNumber brandingInfo={brandingInfo}/> to talk to one of our team.</p>  
                                {/* {error ? <p>API responded with error: {error}</p> : <></>}               */}
                            </>
                    }

                <div className="AvivaPortalReferralSearchDiv">
                    <div 
                        style={{
                            display: 'flex'
                        }}
                        className="AvivaPortalReferralSearchLabelAndInputDiv"
                    >
                        <label 
                            htmlFor="postcode"
                        >
                            Your Postcode
                        </label>
                        <input 
                            id="postcode" 
                            className="form__input" 
                            type="text" 
                            onInput={(e)=>setPostCode(e.target.value)}
                        />
                    </div>
                    <button 
                        href="#" 
                        className="green-button"
                        onClick={searchRequest}
                        disabled={searchStatus}
                    >
                        {
                            searchStatus ? 
                                <LoadingIndicator 
                                    size={20}
                                    loading={searchStatus} 
                                    color={"#FFFFFF"}
                                /> :
                                "Search"
                        }
                        
                    </button>
                </div>
                <div>
                    <p>
                        {error}
                    </p>
                </div>
            </div>
        </>
    )
}

export default ReferralSearch