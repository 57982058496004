import React, { useState, useEffect } from "react";
import { LoadingIndicator } from '../../lib/iprs-react-library/src/index';
         
const PaymentForm = ({api, setPaymentResult, token}) => {
    const [nameOnCard, setNameOnCard] = useState('');
    const [cardNumber1, setCardNumber1] = useState('');
    const [cardNumber2, setCardNumber2] = useState('');
    const [cardNumber3, setCardNumber3] = useState('');
    const [cardNumber4, setCardNumber4] = useState('');
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryYear, setExpiryYear] = useState('');
    const [securityNumber, setSecurityNumber] = useState('');

    const [paymentButton, setPaymentButton] = useState(true);

    // Defines number of fields in the form which is used to move the 
    // focus to the next input when max number of char is reached
    // const numOfFields = 13;

    // Checks for empty input fields and disables the confirm button
    useEffect(()=>{
        if(nameOnCard &&
           cardNumber1 &&
           cardNumber2 &&
           cardNumber3 &&
           cardNumber4 && 
           expiryMonth &&
           expiryYear &&
           securityNumber
        ){
            setPaymentButton(false);
        } else {
            setPaymentButton(true);
        }
    }, [nameOnCard, cardNumber1, cardNumber2, cardNumber3, cardNumber4, expiryMonth, expiryYear, securityNumber])

    // Checks for length of the numerical value entered in the input fields
    // If it is, it will move the focus to the next field.
    const maxNumberCheck = (e) => {
        const {maxLength, value, name} = e.target;
        const [fieldIndexName,fieldIndex] = name.split("-");

        if(fieldIndexName !== ''){
            return null;
        }

        // Check if the value is equal or bigger than maxLength set at the attribute
        if(value.length >= maxLength){
            // Check if it's not the last input field
            // if(parseInt(fieldIndex, 10) < numOfFields){
            // }
            // Get the next input field
            const nextSibling = document.querySelector(
                `input[name=cardDetail-${parseInt(fieldIndex, 10) + 1}]`
            );
            // If found, focus the next field
            if(nextSibling !== null){
                nextSibling.focus();
            }
        }
    }

    // Triggers a function when value of input fields change
    const handleChange = (e) => {
        maxNumberCheck(e);
    }

    const Token = token;

    // Billing Address related codes.
    const [postcode, setPostcode] = useState('');
    const [addressList, setAddressList] = useState([])

    const [address1stLine, setAddress1stLine] = useState('');
    const [address2ndLine, setAddress2ndLine] = useState('');
    const [town, setTown] = useState('');
    const [county, setCounty] = useState('');
    
    // Billing Address postcode checker
    const billingAddressPostcodeChecker = (e) => {
        setPostcode(e.target.value);
    }

    const [addressLookUpError, setAddressLookUpError] = useState('')

    const addressLookupWithPostcode = (e) => {
        setAddressLookUpError('')
        e.preventDefault()
        const params = {
            Postcode: postcode
        }

        api.transact('AddressLookup', params)
        .then(r => {
            if(r.apiResult){
                setAddressList(r.apiResult);
            }
        })
        .catch(err => {
            // console.log(err);
            setAddressLookUpError(err.apiText)
        })
    }
    
    const addressSelect = (e) => {
        // console.log(addressList[e.target.value]);
        const index = e.target.value
        setAddress1stLine(addressList[index].Address1);
        setAddress2ndLine(addressList[index].Address2);
        setTown(addressList[index].Town);
        setCounty(addressList[index].County)
    }
    
    // State to store spinner status
    const [makePaymentLoader, setMakePaymentLoader] = useState(false);
    
    // Makes payment API request when Confirm button is clicked
    const makePayment = () => {

        setMakePaymentLoader(true);

        const params = {
            "Token" : Token,
            "CardNo" : {
                "Part1": cardNumber1,
                "Part2": cardNumber2,
                "Part3": cardNumber3,
                "Part4": cardNumber4,
            },
            "ExpiryDate":{
                "Month": expiryMonth,
                "Year": expiryYear
            },
            "CV2":securityNumber,
            "Address1": address1stLine,
            "Address2": address2ndLine,
            "Town": town,
            "County": county,
            "Postcode": postcode
        }

        api.transact("MakePortalPayment", params)
        .then(r => {
            // console.log('MakePortalPayment: ' + r)
            if(r.apiStatus === "OK"){
                setPaymentResult('Success');
            }else{
                setPaymentResult('Unsuccessful');
            }
            setMakePaymentLoader(false);
        })
        .catch(err=> {
            // console.log(err);
            setPaymentResult('Error');
            if(err){
                return null
            }
            setMakePaymentLoader(false);
        });
    }

    return (
        <div className="payment main__section">
            <form className="payment">
                <div className='payment form__group'>

                    {/* Card Detail Section */}
                    <div>
                        {/* 
                            Form Title 
                        */}
                        <div className="form__title paymentDetailTitle">
                            <h2>Payment details</h2>
                        </div>

                        {/* 
                            Card holder name in a single field.
                            pattern="[-a-zA-Z'. ]*" is used to validate the field to contain only the 
                            characters and symbols allowed for names on payment cards in this input field
                        */}
                        <div className="form__field">
                            <label htmlFor="nameOnCard" >Name on Card</label>
                            <div className="card_parts">
                                <input 
                                    type="text" 
                                    pattern="[a-zA-Z'. ]*"
                                    tabIndex={1} 
                                    maxLength={27}
                                    id="nameOnCard"
                                    onInput={(e)=>
                                        setNameOnCard((v) => e.target.validity.valid ? e.target.value.toUpperCase() : v)
                                    }
                                    value={nameOnCard}
                                    name="cardDetail-1"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        {/* 
                            - ng-required is for angular 
                            - Tried keyup prop for input field but some mobile browsers don't support them
                            - onInput seems to work better than onChange
                        */}

                        {/*Card Number divided in 4 fields*/}
                        <div className="form__field">
                            <label>Card Number</label>
                            <div className="card_parts">
                                <input 
                                    type="text" 
                                    pattern="[0-9]*" 
                                    tabIndex={2} 
                                    maxLength={4} 
                                    id='cardNumber1'
                                    onInput={(e)=>
                                        setCardNumber1((v) => e.target.validity.valid ? e.target.value : v )
                                    }
                                    value={cardNumber1}
                                    name="cardDetail-2"
                                    onChange={handleChange}
                                />
                                <input 
                                    type="text" 
                                    pattern="[0-9]*" 
                                    tabIndex={3} 
                                    maxLength={4} 
                                    id='cardNumber2'
                                    onInput={(e)=>
                                        setCardNumber2((v) => e.target.validity.valid ? e.target.value : v )
                                    }
                                    value={cardNumber2}
                                    name="cardDetail-3"
                                    onChange={handleChange}
                                />
                                <input 
                                    type="text" 
                                    pattern="[0-9]*" 
                                    tabIndex={4} 
                                    maxLength={4} 
                                    id='cardNumber3'
                                    onInput={(e)=>
                                        setCardNumber3((v) => e.target.validity.valid ? e.target.value : v )
                                    }
                                    value={cardNumber3}
                                    name="cardDetail-4"
                                    onChange={handleChange}
                                />
                                <input 
                                    type="text" 
                                    pattern="[0-9]*" 
                                    tabIndex={5} 
                                    maxLength={4} 
                                    id='cardNumber4'
                                    onInput={(e)=>
                                        setCardNumber4((v) => e.target.validity.valid ? e.target.value : v )
                                    }
                                    value={cardNumber4}
                                    name="cardDetail-5"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        {/*Card Expiry Date divided in 2 fields */}
                        <div className="form__field">
                            <label>Expiry</label>
                            <div className="card_parts">
                                <label className="expiryInputLabel">Month</label>
                                <input 
                                    type="text" 
                                    pattern="[0-9]*"
                                    tabIndex={6} 
                                    maxLength={2} 
                                    id="expiryMonth"
                                    onInput={(e)=>
                                        setExpiryMonth((v) => e.target.validity.valid ? e.target.value : v )
                                    }
                                    value={expiryMonth}
                                    name="cardDetail-6"
                                    onChange={handleChange}
                                />
                                <label className="expiryInputLabel">Year</label>
                                <input 
                                    type="text"
                                    pattern="[0-9]*" 
                                    tabIndex={7} 
                                    maxLength={2} 
                                    id="expiryYear"
                                    onInput={(e)=>
                                        setExpiryYear((v) => e.target.validity.valid ? e.target.value : v)
                                    }
                                    value={expiryYear}
                                    name="cardDetail-7"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        {/*Card Security Number in a single field*/}
                        <div className="form__field">
                            <label>Security number</label>
                            <div className="card_parts">
                                <input 
                                    type="text"
                                    pattern="[0-9]*" 
                                    tabIndex={8} 
                                    maxLength={4} 
                                    id="securityNumber"
                                    onInput={(e)=>
                                        setSecurityNumber((v) => e.target.validity.valid ? e.target.value : v)
                                    }
                                    value={securityNumber}
                                    name="cardDetail-8"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <br />
                        
                        <div className="form__info">
                            <p>&pound;1 will be taken from your card to authenticate your card.</p>
                            <p>This will be automatically refunded within 5 days.</p>
                        </div>
                    </div>

                    <hr class="paymentHr"/>

                    {/* Billing Address Section */}
                    <div>
                        {/* 
                            Form Title 
                        */}
                        <div className="form__title">
                            <h2>Billing Address</h2>
                        </div>  

                        <div className="form__field">
                            <label>Postcode</label>
                            <div className="billingAddressPostcodeFieldAndButton">
                                <div className="card_parts postcodeField">
                                    <input 
                                        type="text" 
                                        // pattern={postcodeRegex}
                                        tabIndex={9} 
                                        maxLength={27}
                                        id="billingAddressPostcodeField"
                                        onInput={(e)=>billingAddressPostcodeChecker(e)}
                                        value={postcode}
                                        name="cardDetail-1"
                                        onChange={handleChange}
                                    />
                                </div>
                                <button className="card_parts findAddressFieldButton" onClick={(e)=>addressLookupWithPostcode(e)}>Find Address</button>
                            </div>
                        </div>
                        {
                            addressLookUpError !== '' ?
                                <div className="form__field">
                                    <label></label>
                                    <div>Find Address Error: {addressLookUpError}</div>
                                </div>:
                                <></>
                        }

                        {
                            addressList.length !== 0 ? 
                                    <div className="form__field">
                                        <label></label>
                                        <div>
                                            <select 
                                                class="addressListSelect" 
                                                onChange={(e)=>addressSelect(e)}
                                                tabIndex={10}
                                            >
                                                {   
                                                    addressList.length !== 0 ? 
                                                        addressList.map((address, index)=>{
                                                            return <option value={index} key={address.Postcode + index}>
                                                                {address.Address1} {address.Address2} {address.Town} {address.County} {address.Postcode}
                                                            </option>
                                                        })
                                                        : <></>    
                                                }
                                            </select>
                                        </div>
                                    </div> 
                                :
                                    <></>
                        }

                        <div className="form__field">
                            <label>1st Line of Address</label>
                            <div className="card_parts">
                                <input 
                                    type="text" 
                                    tabIndex={10}
                                    id="billingAddressPostcodeField"
                                    onInput={(e)=>
                                        setAddress1stLine(e.target.value)
                                    }
                                    value={address1stLine}
                                    name="cardDetail-1"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="form__field">
                            <label>2nd Line of Address</label>
                            <div className="card_parts">
                                <input 
                                    type="text" 
                                    pattern="[-a-zA-Z'. ]*"
                                    tabIndex={11}
                                    id="billingAddressPostcodeField"
                                    onInput={(e)=>
                                        setAddress2ndLine(e.target.value)
                                    }
                                    value={address2ndLine}
                                    name="cardDetail-1"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        
                        <div className="form__field">
                            <label>Town</label>
                            <div className="card_parts">
                                <input 
                                    type="text" 
                                    pattern="[-a-zA-Z'. ]*"
                                    tabIndex={12}
                                    id="billingAddressPostcodeField"
                                    onInput={(e)=>
                                        setTown(e.target.value)
                                    }
                                    value={town}
                                    name="cardDetail-1"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="form__field">
                            <label>County</label>
                            <div className="card_parts countyField">
                                <input 
                                    type="text" 
                                    tabIndex={13}
                                    id="billingAddressPostcodeField"
                                    onInput={(e)=>
                                        setCounty(e.target.value)
                                    }
                                    value={county}
                                    name="cardDetail-1"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="paymentFormMessage">
                    Your personnal information provided to us will be used for purposes of booking your appointment.<br/>
                    To find out more about our promise to protecting your personal information please see our <span><a href="http://127.0.0.1:3001/">Privacy Policy</a></span>
                </div>

                <br/>

                <div className="form__info">
                    {paymentButton ? 
                        (<p>Please complete all fields.</p>) : 
                        (<p style={{opacity: 0.5}}>Please complete all fields.</p>)
                    }
                </div>

                <button 
                    disabled={paymentButton}
                    type="button" 
                    className="payment form__action" 
                    tabIndex={9} 
                    value="Confirm" 
                    onClick={makePayment}
                    style={{
                        marginBottom: '50px',
                        height: '42px'
                    }}
                >
                    {!makePaymentLoader ? 
                        "Confirm" : 
                        <LoadingIndicator 
                        size={20}
                        loading={makePaymentLoader} 
                        color={"#FFFFFF"}
                        />
                    }
                </button>
            </form>
        </div>
    )
};

export default PaymentForm;